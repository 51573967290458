import { Box } from "@mui/material";
import Grid from "@mui/material/Unstable_Grid2/";
import React, { useState } from "react";
import Layout from "../../../Layout";
import { isEmpty } from "lodash";
import DisplayErrors from "../../../../components/DisplayErrors";
import MemberOrder from "./MemberOrder";
import { MemberNavWrapper } from "../Navigation/MemberNavWrapper";
import MemberHeader from "../MemberHeader";
import { agentHasRole, hasPermission } from "../../../../utils/utils";
import GenericRouteButton from "../../../../components/GenericRouteButton";
import { usePage } from "@inertiajs/react";
import ModalRecurring from "../../../../components/Modals/ModalRecurring";
var MemberOrders = function (_a) {
    var response_data = _a.response_data, serverErrors = _a.errors;
    var _b = useState(true), menuOpen = _b[0], toggleMenuOpen = _b[1];
    var auth = usePage().props.auth;
    var member = response_data.member, all_documents = response_data.all_documents, activity_log = response_data.activity_log;
    return (React.createElement(Layout, { title: member.full_name + "'s Orders ", menuOpen: menuOpen, toggleMenuOpen: toggleMenuOpen },
        React.createElement(Grid, { container: true, xs: 12, spacing: 2 },
            React.createElement(Grid, { container: true, xs: 12, justifyContent: "center", alignContent: "center" },
                React.createElement(MemberNavWrapper, { member: member, all_documents: all_documents, activity_log: activity_log })),
            !isEmpty(serverErrors) && (React.createElement(Grid, { container: true, spacing: 2 },
                React.createElement(Grid, { xs: 12 },
                    React.createElement(Box, { my: 2 },
                        React.createElement(DisplayErrors, { errors: serverErrors }))))),
            React.createElement(Grid, { xs: 3 },
                React.createElement(MemberHeader, { member: member })),
            React.createElement(Grid, { xs: true, m: 2, container: true, justifyContent: "end", alignContent: "center" },
                hasPermission(auth, "add orders") && (React.createElement(Grid, null,
                    React.createElement(GenericRouteButton, { route_name: "create_order", data: member.id, buttonText: "Add Order", backgroundColor: "#f2a22f" }))),
                (agentHasRole(auth, "msr") ||
                    agentHasRole(auth, "admin")) && (React.createElement(Grid, null,
                    React.createElement(ModalRecurring, { current_setting: member.recurring, member_id: member.id, size: "small", style: {
                            backgroundColor: "#392869",
                            color: "#FFF",
                            fontSize: "11px",
                            fontWeight: "400",
                        } })))),
            React.createElement(Grid, { xs: 12 },
                React.createElement(Box, { m: 2 },
                    React.createElement(MemberOrder, { member: member }))))));
};
export default MemberOrders;
