import React, { useState } from "react";
import { Button, Modal, Dialog, DialogActions, DialogTitle, Alert, Snackbar, LinearProgress, } from "@mui/material";
import { useForm } from "react-hook-form";
import axios from "axios";
import { GridCheckIcon } from "@mui/x-data-grid";
var ModalEmailVerification = function (_a) {
    var member_guid = _a.member_guid, member_id = _a.member_id, _b = _a.size, size = _b === void 0 ? "small" : _b, _c = _a.style, style = _c === void 0 ? {
        backgroundColor: "#1871bf",
        color: "#FFF",
    } : _c;
    var _d = useForm(), handleSubmit = _d.handleSubmit, control = _d.control, formErrors = _d.formState.errors;
    var _e = useState(false), open = _e[0], setOpen = _e[1];
    var _f = useState(false), showLoadingSnackBar = _f[0], setShowLoadingSnackBar = _f[1];
    var _g = useState(false), showSnackBar = _g[0], setShowSnackBar = _g[1];
    var handleChange = function () {
        setOpen(true);
    };
    var handleClose = function () {
        setOpen(false);
    };
    var submitUpdate = function (data) {
        setOpen(false);
        setShowLoadingSnackBar(true);
        axios
            .post("/email-verification/send", {
            member_guid: member_guid,
        })
            .then(function () {
            setShowSnackBar(true);
            axios.post("/member/update/" + member_id, {
                member: { email_verification_sent: true },
            });
            axios.post("/member/log-action/" + member_id, {
                action: "send-email-verification",
                text: "Sent Email Verification.",
            });
            setShowLoadingSnackBar(false);
        })
            .catch(function () {
            console.error("Error sending email verification.");
        });
    };
    return (React.createElement(React.Fragment, null,
        React.createElement(Button, { variant: "contained", style: style, onClick: handleChange, size: size }, "Send Email Verification"),
        React.createElement(Modal, { open: open, onClose: handleClose },
            React.createElement(Dialog, { open: open, onClose: handleClose },
                React.createElement(DialogTitle, null, "Send Email Verification"),
                React.createElement(DialogActions, null,
                    React.createElement(Button, { onClick: handleClose, color: "primary", size: "small" }, "Cancel"),
                    React.createElement(Button, { onClick: handleSubmit(submitUpdate), color: "primary" }, "Send")))),
        React.createElement(Snackbar, { open: showSnackBar, anchorOrigin: { vertical: "top", horizontal: "center" }, autoHideDuration: 5000, message: "Sent Verification Email", onClose: function () {
                setShowSnackBar(false);
            } },
            React.createElement(Alert, { icon: React.createElement(GridCheckIcon, { fontSize: "inherit" }), severity: "success" }, "Sent Verification Email")),
        React.createElement(Snackbar, { open: !showSnackBar && showLoadingSnackBar, anchorOrigin: { vertical: "top", horizontal: "center" }, message: "Sending Verification Email" },
            React.createElement(Alert, { severity: "info" },
                "Sending Verification Email",
                React.createElement(LinearProgress, { color: "info", value: 40, sx: {
                        position: "absolute",
                        bottom: 0,
                        left: 0,
                        right: 0,
                        borderRadius: 0,
                    } })))));
};
export default ModalEmailVerification;
