import React, { useState } from "react";
import { Button, CircularProgress, Dialog, DialogActions, DialogContent, Modal, } from "@mui/material";
import Grid from "@mui/material/Unstable_Grid2";
import axios from "axios";
import AllAbsBrokers from "../../Pages/AbsBroker/AllAbsBrokers";
var ModalSearchBroker = function (_a) {
    var _b = _a.button_color, button_color = _b === void 0 ? "#22c5d1" : _b, _c = _a.button_font, button_font = _c === void 0 ? "#FFF" : _c, _d = _a.size, size = _d === void 0 ? "small" : _d, _e = _a.fullWidth, fullWidth = _e === void 0 ? false : _e, _f = _a.variant, variant = _f === void 0 ? "contained" : _f;
    var _g = useState(false), openSearchModal = _g[0], setOpenSearchModal = _g[1];
    var _h = useState("loading"), brokerData = _h[0], setBrokerData = _h[1];
    var handleOpenSearch = function () {
        setOpenSearchModal(true);
        if (brokerData == "loading") {
            axios
                .get("/user/abs", { params: { for_modal: true } })
                .then(function (res) {
                setBrokerData(res.data.data);
            })
                .catch(function () {
                console.error("Error getting brokers.");
            });
        }
    };
    var handleCloseSearch = function () {
        setOpenSearchModal(false);
    };
    return (React.createElement(React.Fragment, null,
        React.createElement(Button, { onClick: handleOpenSearch, variant: variant, fullWidth: fullWidth, size: size, style: {
                backgroundColor: button_color,
                color: button_font,
            } }, "Brokers"),
        React.createElement(Modal, { open: openSearchModal, onClose: handleCloseSearch },
            React.createElement(Dialog, { maxWidth: "lg", open: openSearchModal, onClose: handleCloseSearch },
                React.createElement(DialogContent, { style: { minWidth: "800px" } }, (brokerData === null || brokerData === void 0 ? void 0 : brokerData.response_data) ? (React.createElement(Grid, { xs: true, m: 2 },
                    React.createElement(AllAbsBrokers, { response_data: brokerData === null || brokerData === void 0 ? void 0 : brokerData.response_data, in_modal: true }))) : (React.createElement(CircularProgress, null))),
                React.createElement(DialogActions, { style: { justifyContent: "space-evenly" } },
                    React.createElement(Button, { onClick: handleCloseSearch, color: "primary" }, "Close"))))));
};
export default ModalSearchBroker;
