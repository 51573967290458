import React, { useState } from "react";
import { Box, Button, Typography } from "@mui/material";
import { ArrowDownward, ArrowUpward } from "@mui/icons-material";
import Grid from "@mui/material/Unstable_Grid2";
import { Link, usePage } from "@inertiajs/react";
import route from "ziggy-js";
import NumberFormat from "react-number-format";
import { hasPermission } from "../../../../utils/utils";
import MemberDonationDetail from "./MemberDonationDetail";
var MemberDonation = function (_a) {
    var _b, _c;
    var member = _a.member, donation = _a.donation, _d = _a.showDefault, showDefault = _d === void 0 ? false : _d;
    var auth = usePage().props.auth;
    var _e = useState(showDefault), show = _e[0], setShow = _e[1];
    var donation_date = new Date(donation === null || donation === void 0 ? void 0 : donation.donation_date)
        .toLocaleDateString("en-US", {
        timeZone: "UTC",
    })
        .replace(/\//g, "-");
    return (React.createElement(Grid, { container: true, style: {
            borderLeft: "10px #E21D37 solid",
            borderRight: "2px #E21D37 solid",
            borderBottom: "2px #E21D37 solid",
            borderTop: "2px #E21D37 solid",
            padding: "5px",
            borderRadius: "3px",
        } },
        React.createElement(Grid, { xs: 4 },
            React.createElement(Box, { my: 2, mx: 1 },
                React.createElement(Link, { href: (donation === null || donation === void 0 ? void 0 : donation.member_id)
                        ? route("show_member", {
                            id: donation === null || donation === void 0 ? void 0 : donation.member_id,
                        })
                        : "#", style: {
                        color: "#000",
                    } },
                    React.createElement(Typography, { variant: "body2", style: { fontWeight: "bold" } }, member.member_spouse
                        ? "".concat((_b = member === null || member === void 0 ? void 0 : member.spouse_full_name) !== null && _b !== void 0 ? _b : "N/A", " (Spouse)")
                        : "".concat((_c = member === null || member === void 0 ? void 0 : member.full_name) !== null && _c !== void 0 ? _c : "N/A", " (Member)"))),
                React.createElement(Typography, { variant: "subtitle1" },
                    "Donation id: ", donation === null || donation === void 0 ? void 0 :
                    donation.id),
                React.createElement(Typography, { variant: "subtitle1", style: { color: "#9e9e9e" } },
                    "#", donation === null || donation === void 0 ? void 0 :
                    donation.member_id))),
        React.createElement(Grid, { xs: 3 },
            React.createElement(Box, { my: 2, mx: 1 },
                React.createElement(Typography, { variant: "body2" },
                    "Amount:",
                    " ",
                    React.createElement("strong", null,
                        React.createElement(NumberFormat, { value: donation === null || donation === void 0 ? void 0 : donation.amount, displayType: "text", decimalScale: 2, fixedDecimalScale: true, thousandSeparator: true, prefix: "$" }))),
                React.createElement(Typography, { variant: "body2" },
                    "Origin:",
                    React.createElement("strong", null,
                        " ", donation === null || donation === void 0 ? void 0 :
                        donation.donation_origin)),
                React.createElement(Typography, { variant: "body2" },
                    "Status:",
                    React.createElement("strong", null,
                        " ", donation === null || donation === void 0 ? void 0 :
                        donation.status)),
                React.createElement(Typography, { variant: "body2" },
                    "Donated at:",
                    React.createElement("strong", null,
                        " ",
                        donation_date)))),
        React.createElement(Grid, { xs: 4 },
            React.createElement(Box, { my: 2, mx: 1 },
                React.createElement(Typography, { variant: "body2" },
                    "UTM Objective:",
                    React.createElement("strong", null,
                        " ", donation === null || donation === void 0 ? void 0 :
                        donation.utm_objective)),
                React.createElement(Typography, { variant: "body2" },
                    "UTM Source:",
                    React.createElement("strong", null,
                        " ", donation === null || donation === void 0 ? void 0 :
                        donation.utm_source)),
                React.createElement(Typography, { variant: "body2" },
                    "UTM Medium:",
                    React.createElement("strong", null,
                        " ", donation === null || donation === void 0 ? void 0 :
                        donation.utm_medium)),
                React.createElement(Typography, { variant: "body2" },
                    "UTM Campaign:",
                    React.createElement("strong", null,
                        " ", donation === null || donation === void 0 ? void 0 :
                        donation.utm_campaign)),
                React.createElement(Typography, { variant: "body2" },
                    "UTM Content:",
                    React.createElement("strong", null,
                        " ", donation === null || donation === void 0 ? void 0 :
                        donation.utm_content)))),
        React.createElement(Grid, { container: true, xs: 1, justifyContent: "center", alignContent: "center" },
            React.createElement(Box, { my: 2 }, hasPermission(auth, "edit donations") && (React.createElement(Button, { onClick: function () { return setShow(!show); } }, show ? (React.createElement(ArrowUpward, { style: {
                    fontSize: 50,
                    color: "#E21D37",
                    textDecoration: "none",
                } })) : (React.createElement(ArrowDownward, { style: { fontSize: 50, color: "#E21D37" } })))))),
        show && (React.createElement(MemberDonationDetail, { member: member, donation: donation }))));
};
export default MemberDonation;
