import React, { useState } from "react";
import { Button, Modal, Dialog, DialogActions, DialogContent, DialogTitle, MenuItem, Alert, Snackbar, } from "@mui/material";
import Grid from "@mui/material/Unstable_Grid2";
import { useForm } from "react-hook-form";
import GenericControllerDropdownInput from "../GenericFormFields/DropdownInput";
import axios from "axios";
import { GridCheckIcon } from "@mui/x-data-grid";
var ModalRecurring = function (_a) {
    var current_setting = _a.current_setting, member_id = _a.member_id, _b = _a.size, size = _b === void 0 ? "small" : _b, _c = _a.style, style = _c === void 0 ? {
        backgroundColor: "#392869",
        color: "#FFF",
    } : _c;
    var _d = useForm(), handleSubmit = _d.handleSubmit, control = _d.control, formErrors = _d.formState.errors;
    var _e = useState(false), open = _e[0], setOpen = _e[1];
    var _f = useState(false), showSnackBar = _f[0], setShowSnackBar = _f[1];
    var handleChange = function () {
        setOpen(true);
    };
    var handleClose = function () {
        setOpen(false);
    };
    var submitUpdate = function (data) {
        setOpen(false);
        var newValue = data.member.recurring ? "yes" : "no";
        var oldValue = current_setting ? "yes" : "no";
        axios
            .post("/member/update/" + member_id, data)
            .then(function () {
            setShowSnackBar(true);
            axios.post("/member/log-action/" + member_id, {
                action: "update-recurring",
                text: "Updated Recurring from " +
                    oldValue +
                    " to " +
                    newValue,
            });
        })
            .catch(function () {
            console.error("Error updating auto-renewal setting.");
        });
    };
    return (React.createElement(React.Fragment, null,
        React.createElement(Button, { variant: "contained", style: style, onClick: handleChange, fullWidth: true, size: size }, "Update Auto Renewal"),
        React.createElement(Modal, { open: open, onClose: handleClose },
            React.createElement(Dialog, { open: open, onClose: handleClose },
                React.createElement(DialogTitle, null, "Change Auto-Renewal Setting"),
                React.createElement(DialogContent, { style: { minWidth: "500px" } },
                    React.createElement(Grid, { container: true, xs: 12, spacing: 1 },
                        React.createElement(GenericControllerDropdownInput, { formControlSX: { my: 1 }, name: "member[recurring]", control: control, label: "Automatic Renewal", id: "recurring_billing_setting", defaultValue: current_setting ? 1 : 0, handleChange: handleChange, rules: { required: true }, options: [
                                React.createElement(MenuItem, { key: "recur-true", value: 1 }, "Yes"),
                                React.createElement(MenuItem, { key: "recur-false", value: 0 }, "No"),
                            ] }))),
                React.createElement(DialogActions, null,
                    React.createElement(Button, { onClick: handleClose, color: "primary", size: "small" }, "Cancel"),
                    React.createElement(Button, { onClick: handleSubmit(submitUpdate), color: "primary" }, "Change")))),
        React.createElement(Snackbar, { open: showSnackBar, anchorOrigin: { vertical: "top", horizontal: "center" }, autoHideDuration: 5000, message: "Auto Renewal Updated", onClose: function () {
                setShowSnackBar(false);
            } },
            React.createElement(Alert, { icon: React.createElement(GridCheckIcon, { fontSize: "inherit" }), severity: "success" }, "The member's Auto Renewal Setting was updated"))));
};
export default ModalRecurring;
