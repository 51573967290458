// works returns date time
export var format_datetime = function (unformatted_date, end_of_day) {
    var end_seconds = end_of_day ? 86399999 : 0;
    var frontEndDate = new Date(unformatted_date);
    var tz_offset = frontEndDate.getTimezoneOffset();
    var UTCDate = new Date(Date.parse(frontEndDate.toISOString().split("T")[0]) +
        tz_offset * 60000 +
        end_seconds);
    return UTCDate;
};
export var format_date = function (date) {
    return date.toISOString().split("T")[0];
};
export var addBusinessDays = function (originalDate, numDaysToAdd) {
    var Sunday = 0;
    var Saturday = 6;
    var daysRemaining = numDaysToAdd;
    var newDate = new Date(originalDate);
    while (daysRemaining > 0) {
        newDate.setDate(newDate.getDate() + 1);
        if (newDate.getDay() !== Sunday && newDate.getDay() !== Saturday) {
            daysRemaining--;
        }
    }
    return newDate;
};
export var dispositionFollowUp = function (disposition_id) {
    var date = new Date();
    switch (disposition_id) {
        case "12": // Needs Callback
            return date.toISOString().slice(0, 10);
        case "5": // Attempted First Call
        case "9": // Emailed Self Enrollment
        case "24": // Outbound Call Contact
            return addBusinessDays(date, 1).toISOString().slice(0, 10);
        case "2": // Application Submitted to Carrier
        case "6": // Attempted Another Call
        case "36": // Retireflo Link Sent
            return addBusinessDays(date, 2).toISOString().slice(0, 10);
        case "14": // Approved - Waiting for acceptance
            return addBusinessDays(date, 5).toISOString().slice(0, 10);
        case "8": // Application Sent to Client
        case "13": // Quote / Info Sent to Client
            return addBusinessDays(date, 7).toISOString().slice(0, 10);
        default:
            return;
    }
};
export var rosemarkLeadDispositionFollowUp = function (disposition_id) {
    var date = new Date();
    switch (disposition_id) {
        case "25": // Kept Existing Plan
        case "31": // Declined by Client
        case "33": // Withdrawn No Member Response
        case "34": // Withdrawn No Agent Response
            return null; // removes follow up date
        case "12": // Needs Callback
            return date.toISOString().slice(0, 10);
        case "9": // Emailed Self Enrollment
        case "24": // Outbound Call Contact
            return addBusinessDays(date, 1).toISOString().slice(0, 10);
        case "5": // Attempted First Call
        case "6": // Attempted Another Call
        case "35": // Meeting Follow-Up
            return addBusinessDays(date, 2).toISOString().slice(0, 10);
        case "13": // Quote / Info Sent to Client
            return addBusinessDays(date, 3).toISOString().slice(0, 10);
        case "14": // Approved - Waiting for acceptance
            return addBusinessDays(date, 5).toISOString().slice(0, 10);
        case "8": // Application Sent to Client
            return addBusinessDays(date, 7).toISOString().slice(0, 10);
        case "21": // Member will call back if interested/AEP
            return addBusinessDays(date, 10).toISOString().slice(0, 10);
        default:
            return;
    }
};
export var rosemarkAppDispositionFollowUp = function (disposition_id) {
    var date = new Date();
    switch (disposition_id) {
        case "25": // Kept Existing Plan
        case "31": // Declined by Client
        case "33": // Withdrawn No Member Response
        case "34": // Withdrawn No Agent Response
            return null; // removes follow up date
        case "28": // Awaiting Agent Signature
            return date.toISOString().slice(0, 10);
        case "22": // Awaiting Client Signature
            return addBusinessDays(date, 1).toISOString().slice(0, 10);
        case "14": // Approved - Waiting for acceptance
        case "30": // Requested Information Sent to Carrier
        case "32": // Delivery Requirements Sent to Client
        case "35": // Meeting Follow-Up
            return addBusinessDays(date, 2).toISOString().slice(0, 10);
        case "13": // Quote / Info Sent to Client
            return addBusinessDays(date, 3).toISOString().slice(0, 10);
        case "2": // Application Submitted to Carrier
        case "15": // Delivery requirements sent to carrier
        case "29": // Submitted - Additional Information Requested
            return addBusinessDays(date, 5).toISOString().slice(0, 10);
        default:
            return;
    }
};
